import { TypedDocumentNode, gql } from '@apollo/client';

import {
  ClaimNBAReferralRewardsMutation,
  ClaimNBAReferralRewardsMutationVariables,
  NBAReferralRewardsQuery,
  NBAReferralRewardsQueryVariables,
} from './__generated__/queries.graphql';

export const NBA_REFERRAL_REWARDS_QUERY = gql`
  query NBAReferralRewardsQuery($referralIDs: [UUID!]!) {
    usSportReferralRewards: nbaReferralRewards(referralIDs: $referralIDs) {
      id
      state
      card {
        slug
        anyPositions
        rarityTyped
        anyPlayer {
          slug
          anyPositions
        }
        anyTeam {
          slug
          pictureUrl
        }
      }
    }
  }
` as TypedDocumentNode<
  NBAReferralRewardsQuery,
  NBAReferralRewardsQueryVariables
>;

export const CLAIM_NBA_REFERRAL_REWARDS_MUTATION = gql`
  mutation ClaimNBAReferralRewardsMutation($referralIDs: [UUID!]!) {
    claimNBAReferralRewards(referralIDs: $referralIDs) {
      id
      state
    }
  }
` as TypedDocumentNode<
  ClaimNBAReferralRewardsMutation,
  ClaimNBAReferralRewardsMutationVariables
>;
