type Props = React.JSX.IntrinsicElements['svg'];
export const Fire = ({ fill = '#101010', ...rest }: Props) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0205512 10.3071C-0.0389141 14.5618 4.65885 16 4.65885 16C1.74505 12.4045 5.55083 11.9251 5.6103 9.10861C6.14548 9.28839 6.20495 10.9663 6.20495 10.9663C7.51319 10.3071 7.57265 7.31086 7.57265 7.31086C11.0216 10.6067 9.11875 15.8801 9.11875 15.8801C13.9949 14.8015 13.9354 9.82772 13.9354 9.82772C13.876 4.91386 10.0702 2.03745 10.3081 2.8764C10.6054 3.71536 10.0107 4.55431 10.0107 4.55431C8.88089 0.0599251 5.55083 0 5.55083 0C6.02655 3.65543 3.64794 5.81273 3.64794 5.81273C2.10184 2.75655 2.22077 4.07491 1.92344 5.93258C-0.336241 8.50936 0.0205512 10.3071 0.0205512 10.3071Z"
      fill={fill}
    />
  </svg>
);
