import { Suspense } from 'react';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';

import { AppLayout } from '@sorare/us-sports/src/components/AppLayout';

const MLBLayout = () => {
  return (
    <Suspense fallback={<LoadingIndicator fullHeight />}>
      <AppLayout />
    </Suspense>
  );
};
export default MLBLayout;
