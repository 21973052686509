import { Outlet } from 'react-router-dom';

import { AppLayout } from '@sorare/us-sports/src/components/AppLayout';

const Layout = () => (
  <AppLayout>
    <Outlet />
  </AppLayout>
);

export default Layout;
