import { ReactNode } from 'react';
import styled from 'styled-components';

import { Container } from 'atoms/layout/Container';
import MarketingPage from 'components/marketing/MarketingPage';
import { AppLayout } from 'components/navigation/AppLayout';

const StyledContainer = styled(Container).attrs({ dir: 'ltr' })`
  padding-top: var(--quadruple-unit);
`;

interface LayoutProps {
  children: ReactNode;
}

export const TextDocumentLayout = ({ children }: LayoutProps) => {
  return (
    <AppLayout>
      <MarketingPage>
        <StyledContainer>{children}</StyledContainer>
      </MarketingPage>
    </AppLayout>
  );
};
