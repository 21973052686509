import { Fragment, ReactNode } from 'react';
import {
  FormattedList,
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { UserFlagReason } from '__generated__/globalTypes';
import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import ExternalLink from 'atoms/navigation/ExternalLink';
import { BodyS } from 'atoms/typography';
import Bold from 'atoms/typography/Bold';
import { TERMS_AND_CONDITIONS } from 'constants/__generated__/routes';
import { PROBATION_UNTIL_HELP_LINK } from 'constants/externalLinks';
import { useCurrentUserContext } from 'contexts/currentUser';
import { CurrentUserProvider_currentUser } from 'contexts/currentUser/__generated__/queries.graphql';
import Warning from 'contexts/intl/Warning';

import { useSetLocale } from '../LanguageSwitcher/useSetLocale';
import { MyClubLink } from '../MyClubLink';

const messages = defineMessages({
  liftOn: {
    id: 'UserFlags.liftOn',
    defaultMessage: 'You will be on probabation until {until}.',
  },
  help: {
    id: 'UserFlags.help',
    defaultMessage:
      'Help us keep Sorare fair and enjoyable for everyone! Click <link>here</link> for more information.',
  },
  multiAccount: {
    id: 'UserFlags.multiAccount2',
    defaultMessage:
      'Friendly reminder to ensure the best experience on Sorare: <b>while your account is still active</b>, we want to remind you that using multiple accounts <accounts></accounts> goes against our <terms>Terms & Conditions</terms>.',
  },
  abusiveLanguage: {
    id: 'UserFlags.abusiveLanguage',
    defaultMessage:
      "We've noticed recent use of abusive language in your communications on Sorare.",
  },
  anticompetitiveLending: {
    id: 'UserFlags.anticompetitiveLending',
    defaultMessage:
      'Friendly reminder to ensure the best experience on Sorare: <b>while your account is still active</b>, please avoid any unfair trading with one another <accounts></accounts> as it goes against our <terms>Terms & Conditions</terms>.',
  },
  emailHardBounce: {
    id: 'UserFlags.emailHardBounce',
    defaultMessage:
      "We've noticed that your email address was invalid, please update it.",
  },
  referralAbuse: {
    id: 'UserFlags.referralAbuse',
    defaultMessage:
      "We've noticed referral abuse behaviors involing your account.",
  },
  referralSpam: {
    id: 'UserFlags.referralSpam',
    defaultMessage:
      "We've noticed referral abuse behaviors involing your account.",
  },
  unfairGallery: {
    id: 'UserFlags.unfairGallery',
    defaultMessage: "We've noticed unfair gallery behaviors on your account.",
  },
});

const StyledWidthContainer = styled(FullWidthContainer)`
  padding-top: var(--double-unit);

  a {
    font-weight: var(--t-bold);
  }
`;

const reasons: Record<UserFlagReason, MessageDescriptor> = {
  [UserFlagReason.MULTI_ACCOUNT]: messages.multiAccount,
  [UserFlagReason.ABUSIVE_LANGUAGE]: messages.abusiveLanguage,
  [UserFlagReason.ANTICOMPETITIVE_LENDING]: messages.anticompetitiveLending,
  [UserFlagReason.EMAIL_HARD_BOUNCE]: messages.emailHardBounce,
  [UserFlagReason.REFERRAL_ABUSE]: messages.referralAbuse,
  [UserFlagReason.REFERRAL_SPAM]: messages.referralSpam,
  [UserFlagReason.UNFAIR_GALLERY]: messages.unfairGallery,
};

const UserFlag = ({
  flag,
}: {
  flag: CurrentUserProvider_currentUser['warningFlags'][number];
}) => {
  const { locale } = useSetLocale();

  return (
    <>
      <FormattedMessage
        {...reasons[flag.reason]}
        values={{
          b: Bold,
          terms: (content: ReactNode[]) => (
            <Link to={TERMS_AND_CONDITIONS}>{content}</Link>
          ),
          accounts: () => {
            if (flag.relatedUsers.length === 0) return null;

            return (
              <>
                (
                <FormattedList
                  type="conjunction"
                  value={flag.relatedUsers.map(user => (
                    <MyClubLink key={user.slug} user={user}>
                      {user.nickname}
                    </MyClubLink>
                  ))}
                />
                ){' '}
              </>
            );
          },
        }}
      />{' '}
      {flag.until && (
        <FormattedMessage
          {...messages.liftOn}
          values={{
            until: flag.until.toLocaleDateString(locale, {
              day: 'numeric',
              month: 'short',
              year:
                flag.until.getFullYear() === new Date().getFullYear()
                  ? undefined
                  : 'numeric',
            }),
          }}
        />
      )}
    </>
  );
};

export const UserFlags = () => {
  const { currentUser } = useCurrentUserContext();

  if (!currentUser || currentUser.warningFlags.length === 0) return null;

  return (
    <StyledWidthContainer>
      <Warning variant="yellow">
        <BodyS>
          {currentUser.warningFlags.map(flag => (
            <Fragment key={flag.id}>
              <UserFlag flag={flag} />{' '}
            </Fragment>
          ))}
          <FormattedMessage
            {...messages.help}
            values={{
              link: (content: ReactNode[]) => (
                <ExternalLink
                  href={PROBATION_UNTIL_HELP_LINK}
                  inline
                  withoutIcon
                >
                  {content}
                </ExternalLink>
              ),
            }}
          />
        </BodyS>
      </Warning>
    </StyledWidthContainer>
  );
};
