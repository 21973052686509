import { ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { HexColor } from '@sorare/core/src/style/types';

const Root = styled.div`
  position: relative;
`;
const Svg = styled.svg`
  width: inherit;
  height: inherit;
`;

const Icon = styled.div`
  position: absolute;
  left: calc(5 * var(--unit));
  top: 50%;
  transform: translateY(-50%);
`;
const Text = styled.text`
  fill: #fff;
`;
const ChildrenWrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  position: absolute;
  inset: 0 10%;
`;

type Props = {
  icon?: ReactNode;
  text?: string;
  fillColor?: HexColor;
  fillOpacity?: number;
  children?: ReactNode;
  className?: string;
};
export const BrandedInfo = ({
  icon,
  text,
  fillColor = 'var(--c-neutral-1000)',
  fillOpacity = 0.07,
  className,
  children,
}: Props) => {
  return (
    <Root className={className}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="338"
        height="65"
        viewBox="0 0 338 65"
        fill="none"
      >
        <path d="M323.178 31.0703H332.387L316.86 64.0703H243.154H0.962832L14.6308 34.7818L14.9628 34.0703H14.1777H4.96563L20.495 1.07031H276.42H336.393L322.725 30.3589L322.393 31.0703H323.178Z" />
        <path
          d="M323.178 31.0703H332.387L316.86 64.0703H243.154H0.962832L14.6308 34.7818L14.9628 34.0703H14.1777H4.96563L20.495 1.07031H276.42H336.393L322.725 30.3589L322.393 31.0703H323.178Z"
          fillOpacity={fillOpacity}
          fill={fillColor}
        />
        {text && (
          <Text x="30%" y="60%" fontSize="100%" textAnchor="left">
            {text}
          </Text>
        )}
      </Svg>
      {icon && <Icon>{icon}</Icon>}
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </Root>
  );
};
