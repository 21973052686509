import { useIntl } from 'react-intl';

import { TextDocumentLayout } from '@sorare/core/src/routing/TextDocument/TextDocumentLayout';
import TextDocument from '@sorare/core/src/routing/TextDocument/lazy';

const LegalNotice = () => {
  const { formatMessage } = useIntl();
  return (
    <TextDocumentLayout>
      <TextDocument
        document={formatMessage({
          id: 'LegalNotice.path',
          defaultMessage: 'LEGAL_NOTICE.md',
        })}
        skipHtml
      />
    </TextDocumentLayout>
  );
};

export default LegalNotice;
