import { TypedDocumentNode, gql } from '@apollo/client';
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { ellipsis } from '@sorare/core/src/atoms/typography/Ellipsis';
import Avatar from '@sorare/core/src/components/user/Avatar';
import { isType } from '@sorare/core/src/gql';

import { CardTransaction_tokenPrice } from './__generated__/index.graphql';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, max-content);
  column-gap: var(--unit);
  row-gap: var(--half-unit);
`;

const Nickname = styled(LabelM)`
  ${ellipsis}
`;

type Props = {
  tokenPrice: CardTransaction_tokenPrice;
};

const getBuyerAndSeller = (deal: CardTransaction_tokenPrice['deal']) => {
  if (isType(deal, 'TokenPrimaryOffer')) {
    return {
      buyer: deal.buyer,
      seller: null,
    };
  }
  if (isType(deal, 'TokenOffer')) {
    return {
      buyer: deal.buyer,
      seller: deal.seller,
    };
  }
  if (isType(deal, 'TokenAuction')) {
    return {
      buyer: deal.bestBid?.bidder || null,
      seller: null,
    };
  }
  return {
    buyer: null,
    seller: null,
  };
};
export const CardTransaction = ({ tokenPrice }: Props) => {
  const { deal } = tokenPrice;

  const { buyer, seller } = getBuyerAndSeller(deal);

  return (
    <Wrapper>
      <Horizontal>
        <FontAwesomeIcon
          icon={faArrowCircleRight}
          color="var(--c-score-high)"
        />
        <LabelM>
          <FormattedMessage
            id="BoxOpeningOnboardingDialog.priceStep.boughtBy"
            defaultMessage="Bought by"
          />
        </LabelM>
      </Horizontal>
      {isType(buyer, 'User') && (
        <Horizontal>
          <Avatar size={2} rounded user={buyer} />
          <Nickname bold>{buyer.nickname}</Nickname>
        </Horizontal>
      )}
      <Horizontal>
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          color="var(--c-static-red-600)"
        />
        <LabelM>
          <FormattedMessage
            id="BoxOpeningOnboardingDialog.priceStep.soldBy"
            defaultMessage="Sold by"
          />
        </LabelM>
      </Horizontal>
      {isType(seller, 'User') ? (
        <Horizontal>
          <Avatar size={2} rounded user={seller} />
          <Nickname bold>{seller?.nickname}</Nickname>
        </Horizontal>
      ) : (
        <LabelM bold>Sorare</LabelM>
      )}
    </Wrapper>
  );
};

CardTransaction.fragments = {
  tokenPrice: gql`
    fragment CardTransaction_tokenPrice on TokenPrice {
      id
      deal {
        ... on TokenAuction {
          id
          bestBid {
            id
            bidder {
              ... on User {
                slug
                nickname
              }
              ... on AnonymousUser {
                id
              }
              ...Avatar_user
            }
          }
        }
        ... on TokenPrimaryOffer {
          id
          buyer {
            slug
            ... on User {
              slug
              nickname
            }
            ...Avatar_user
          }
        }
        ... on TokenOffer {
          id
          buyer {
            ... on User {
              slug
              nickname
            }
            ... on AnonymousUser {
              id
            }
            ...Avatar_user
          }
          seller {
            ... on AnonymousUser {
              id
            }
            ... on User {
              slug
              nickname
            }
            ...Avatar_user
          }
        }
      }
    }
    ${Avatar.fragments.user}
  ` as TypedDocumentNode<CardTransaction_tokenPrice>,
};
