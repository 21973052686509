import { useParams } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import { LANDING } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

const MyOwnClubRedirect = () => {
  const { currentUser } = useCurrentUserContext();
  const { sport } = useParams();
  const path = useParams()['*'];

  if (!currentUser) {
    return <Navigate replace to={LANDING} />;
  }

  return (
    <Navigate replace to={`/${sport}/my-club/${currentUser.slug}/${path}`} />
  );
};

export default MyOwnClubRedirect;
