import { faLink } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';

const Link = styled.a`
  display: flex;
  gap: var(--half-unit);
  font-weight: var(--t-bold);
  align-items: center;

  &.inline {
    display: inline-flex;
  }
`;

interface Props {
  href: string;
  children: ReactNode;
  inline?: boolean;
  withoutIcon?: boolean;
}
export const ExternalLink = ({
  href,
  children,
  inline,
  withoutIcon,
}: Props) => {
  return (
    <Link
      className={classNames({ inline })}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
      {!withoutIcon && <FontAwesomeIcon icon={faLink} size="xs" />}
    </Link>
  );
};

export default ExternalLink;
