import { TypedDocumentNode, gql } from '@apollo/client';

import { markStoriesAsReadInput } from '@sorare/core/src/__generated__/globalTypes';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  MarkStoriesAsReadMutation,
  MarkStoriesAsReadMutationVariables,
} from './__generated__/useMarkStoriesAsRead.graphql';

const MARK_STORIES_AS_READ = gql`
  mutation MarkStoriesAsReadMutation($input: markStoriesAsReadInput!) {
    markStoriesAsRead(input: $input) {
      currentRivalsManager {
        id
        unreadStories {
          id
        }
      }
      stories {
        id
        aasmState
      }
      errors {
        code
        message
        path
      }
    }
  }
` as TypedDocumentNode<
  MarkStoriesAsReadMutation,
  MarkStoriesAsReadMutationVariables
>;

const useMarkStoriesAsRead = () => {
  const [mutate, { loading }] = useMutation(MARK_STORIES_AS_READ);

  return {
    readStories: async (input: markStoriesAsReadInput) => {
      return mutate({
        variables: { input },
      });
    },
    loading,
  };
};

export default useMarkStoriesAsRead;
