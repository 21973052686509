import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Coin from 'atoms/icons/Coin';
import { Horizontal } from 'atoms/layout/flex';
import { LabelXS } from 'atoms/typography';
import { tabletAndAbove } from 'style/mediaQuery';

const Root = styled(Horizontal).attrs({ gap: 0.5 })``;
const Amount = styled(LabelXS)`
  color: var(--c-neutral-600);
  font-weight: bold;
  @media ${tabletAndAbove} {
    font: var(--t-body-m);
    color: var(--c-neutral-600);
    font-weight: bold;
  }
`;

type Props = {
  amount: number;
};
export const CoinAmount = (props: Props) => {
  const { formatNumber } = useIntl();
  const { amount } = props;

  return (
    <Root>
      <Coin />
      <Amount>{formatNumber(amount)}</Amount>
    </Root>
  );
};

export default CoinAmount;
