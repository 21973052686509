import { MessageDescriptor, defineMessage, defineMessages } from 'react-intl';

export enum SettingsPage {
  MY_ACCOUNT = 'account',
  NOTIFICATIONS = 'notifications',
  SECURITY = 'security',
  GLOBAL_PREFERENCES = 'globalPreferences',
  PRIVACY_AND_SAFETY = 'privacyAndSafety',
  PAYMENT = 'payment',
}

export const DEFAULT_PAGE_ID: SettingsPage = SettingsPage.MY_ACCOUNT;

export const PAGE_TITLE = defineMessage({
  id: 'Settings.title',
  defaultMessage: 'Settings',
});

export const TITLES: { [key in SettingsPage]: MessageDescriptor } =
  defineMessages<SettingsPage>({
    [SettingsPage.MY_ACCOUNT]: {
      id: 'Settings.account',
      defaultMessage: 'My Account',
    },
    [SettingsPage.NOTIFICATIONS]: {
      id: 'Settings.notifications',
      defaultMessage: 'Notifications',
    },
    [SettingsPage.SECURITY]: {
      id: 'Settings.security',
      defaultMessage: 'Login & Security',
    },
    [SettingsPage.GLOBAL_PREFERENCES]: {
      id: 'Settings.globalPreferences',
      defaultMessage: 'Global preferences',
    },
    [SettingsPage.PRIVACY_AND_SAFETY]: {
      id: 'Settings.privacyAndSafety',
      defaultMessage: 'Privacy & Safety',
    },
    [SettingsPage.PAYMENT]: {
      id: 'Settings.payment',
      defaultMessage: 'Payment',
    },
  });
