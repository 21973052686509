import { FormattedMessage, useIntl } from 'react-intl';

import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { Tag, Props as TagProps } from '@sorare/core/src/atoms/ui/Tag';

export const ProbablePitcher = (tagProps: TagProps) => {
  const { formatMessage } = useIntl();
  return (
    <Tooltip
      title={formatMessage({
        id: 'ProbablePitcher.tooltip',
        defaultMessage: 'Probable Pitcher',
      })}
    >
      <Tag round small color="green" {...tagProps}>
        <FormattedMessage id="ProbablePitcher.accronym" defaultMessage="PP" />
      </Tag>
    </Tooltip>
  );
};
