import { useIntl } from 'react-intl';

import { TextDocumentLayout } from '@sorare/core/src/routing/TextDocument/TextDocumentLayout';
import TextDocument from '@sorare/core/src/routing/TextDocument/lazy';

const CookiePolicy = () => {
  const { formatMessage } = useIntl();
  return (
    <TextDocumentLayout>
      <TextDocument
        document={formatMessage({
          id: 'CookiePolicy.path',
          defaultMessage: 'COOKIE_POLICY.md',
        })}
      />
    </TextDocumentLayout>
  );
};

export default CookiePolicy;
