import { useIntl } from 'react-intl';

import { TextDocumentLayout } from '@sorare/core/src/routing/TextDocument/TextDocumentLayout';
import TextDocument from '@sorare/core/src/routing/TextDocument/lazy';

const Terms = () => {
  const { formatMessage } = useIntl();
  return (
    <TextDocumentLayout>
      <TextDocument
        document={formatMessage({
          id: 'Terms.path',
          defaultMessage: 'TERMS.md',
        })}
        skipHtml
      />
    </TextDocumentLayout>
  );
};

export default Terms;
