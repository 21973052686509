import { addBreadcrumb } from '@sentry/react';
import { ShouldRevalidateFunction } from 'react-router-dom';

import { sendSafeError } from 'lib/error';

type GetVariablesFn = (params: any, url: URL) => Record<string, unknown>;

const shouldRevalidateFn: (
  getVariables: GetVariablesFn | undefined
) => ShouldRevalidateFunction =
  getVariables =>
  ({ currentParams, nextParams, currentUrl, nextUrl }) => {
    if (!getVariables) {
      return false;
    }

    try {
      // Check if variables passed to query are different
      // This avoids loader being called for url parameter changes
      return (
        JSON.stringify(getVariables(currentParams, currentUrl)) !==
        JSON.stringify(getVariables(nextParams, nextUrl))
      );
    } catch (e) {
      addBreadcrumb({
        type: 'custom',
        message: 'withRouteQuery shouldRevalidate',
      });
      sendSafeError(e);
      return true;
    }
  };

export default shouldRevalidateFn;
