import { Navigate } from '@sorare/routing';

import { INVITE } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import useCurrentSport from '@sorare/core/src/hooks/useCurrentSport';

const ReferralProgram = () => {
  const currentSport = useCurrentSport();
  const { generateSportContextPath } = useSportContext();
  return (
    <Navigate
      to={generateSportContextPath(INVITE, { sport: currentSport })}
      replace
    />
  );
};

export default ReferralProgram;
