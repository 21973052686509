import { FormattedMessage } from 'react-intl';

import { Tag } from '@sorare/core/src/atoms/ui/Tag';

type Props = {
  nbGames: number;
  spellNoGame?: boolean;
  hasTBDGames?: boolean;
};

export const RawGameCount = ({
  nbGames,
  spellNoGame,
  hasTBDGames = false,
}: Props) => {
  if (nbGames === 0 && hasTBDGames) {
    return <FormattedMessage id="gameCount.tbd" defaultMessage="TBD" />;
  }
  if (nbGames === 0) {
    return (
      <>
        {spellNoGame ? (
          <FormattedMessage id="gameCount.noGames" defaultMessage="No Game" />
        ) : (
          'NG'
        )}
      </>
    );
  }
  return (
    <>
      <FormattedMessage
        id="gameCount.games"
        defaultMessage="{nbGames, plural, =0 {NG} one {# Game} other {# Games}}"
        values={{
          nbGames,
        }}
      />
      {hasTBDGames && ' (+)'}
    </>
  );
};

export const GameCount = (props: Props) => {
  const { nbGames } = props;

  if (nbGames === 0) {
    return (
      <Tag small color="grey">
        <RawGameCount {...props} />
      </Tag>
    );
  }
  return (
    <Tag small color="grey">
      <RawGameCount {...props} />
    </Tag>
  );
};
